import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

export const Container = styled.div`
    display: flex;

    justify-content: space-between;
    flex-direction: column;
    @media (min-width: ${breakpoints.lg}) {
        flex-direction: row;
    }
`;

export const ImageContainer = styled.div`
    flex-basis: 30%;

    @media (min-width: ${breakpoints.lg}) {
        margin: 5rem 0 0 3rem;
    }
`;
export const ContentsContainer = styled.div`
    margin: 1rem 0 2rem 0;
    flex-basis: 70%;
    @media (min-width: ${breakpoints.lg}) {
        margin-left: 0;
    }
`;
export const Title = styled.h1`
    font-size: 1.2rem;
    padding-bottom: 2rem;
`;
export const Content = styled.p`
    padding-bottom: 1rem;
`;
export const ContentBottom = styled.div`
    font-weight: 500;
    padding-bottom: 4rem;
`;
