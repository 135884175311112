import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const AboutImage = () => {
    const data = useStaticQuery(graphql`
        query {
            artWork: file(relativePath: { eq: "KOFFIA-2024-v.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return <Img fluid={data.artWork.childImageSharp.fluid} />;
};

export default AboutImage;
