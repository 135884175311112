import React from "react";
import AboutImage from "./Images";
import {
    Container,
    ImageContainer,
    ContentsContainer,
    Title,
    Content,
    ContentBottom,
} from "./styles";
import PageContainer from "../PageContainer";
import PageTitle from "../PageTitle";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";

const About = () => {
    return (
        <PageContainer>
            <Breadcrumb>
                <BreadcrumbItem Link="/about" Text="About" />
            </Breadcrumb>
            <PageTitle>About KOFFIA</PageTitle>
            <Container>
                <ContentsContainer>
                    <Title>
                        Welcome to the 2024 Korean Film Festival in Australia
                        (KOFFIA)!
                    </Title>
                    <Content>
                        Experience the extraordinary storytelling, breathtaking
                        cinematography, and powerful performances as KOFFIA
                        returns for its 15th year down under! We are thrilled to
                        invite you to immerse yourself in a captivating showcase
                        of Korean cinema, featuring an exciting line-up of
                        Australian Premieres from Korea's top filmmakers. From
                        thrilling thrillers to heartwarming rom-coms, gripping
                        dramas to enchanting fantasies, and everything in
                        between, KOFFIA 2024 promises a cinematic journey that
                        will captivate audiences of all tastes and backgrounds.
                    </Content>
                    <Content>
                        Presented by the Korean Cultural Centre Australia, this
                        year's festival highlights the unique culture of Korea
                        through the lens of cinema, offering something for
                        everyone with films spanning genres such as Thriller,
                        Crime, Rom-com, Drama, Fantasy, Mystery, Family, Comedy,
                        and Action. Each film is presented with English
                        subtitles, ensuring that all can enjoy and appreciate
                        the magic of Korean storytelling.
                    </Content>
                    <Content>
                        This year, we are thrilled to announce our collaboration
                        with local councils and our exciting touring program!
                        KOFFIA 2024 will be visiting Canberra, Benalla,
                        Burnside, and Alice Springs, bringing a selection of
                        films to these communities. The touring program offers
                        free admission to all, welcoming everyone to experience
                        the richness of Korean cinema.
                    </Content>
                    <Content>
                        We extend our heartfelt gratitude to our sponsors,
                        partners, and dedicated film enthusiasts whose support
                        makes KOFFIA 2024 and our touring program possible. Join
                        us as we celebrate the artistry and cultural richness of
                        Korean cinema, fostering deeper cultural exchange and
                        appreciation.
                    </Content>
                    <Content>
                        Mark your calendars and join us for an unforgettable
                        celebration of Korean film at KOFFIA 2024. We look
                        forward to sharing this cinematic adventure with you!
                    </Content>
                    <Content>KOFFIA Team</Content>
                </ContentsContainer>
                <ImageContainer>
                    <AboutImage />
                </ImageContainer>
            </Container>
        </PageContainer>
    );
};

export default About;
